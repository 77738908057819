<template>
  <div class="access-group-list">
    <h2>Access Groups</h2>
    <button class="btn btn-primary mb-4" @click="addAccessGroup">Add New Access Group</button>
    <v-client-table
      :data="accessGroups"
      :columns="columns"
      :options="options"
      @row-click="editAccessGroup"
    ></v-client-table>
  </div>
</template>

<script>
const api = window.api;
export default {
  name: 'AccessGroupList',
  data() {
    return {
      accessGroups: [],
      columns: ['id', 'Description'],
      options: {
        headings: {
          id: 'ID',
          Description: 'Name',
          actions: 'Actions',
        },
        sortable: ['id', 'Description'],
        filterable: ['id', 'Description'],
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        skin: 'table table-striped table-hover',
      },
    };
  },
  mounted() {
    this.fetchAccessGroups();
  },
  methods: {
    async fetchAccessGroups() {
      this.accessGroups = await api.get('/api/accessgroup/');
    },
    editAccessGroup(data) {
      this.$router.push({name: 'accessgroup', params: {id: data.row.id}})
    },
    addAccessGroup() {
      this.$router.push({name: 'accessgroup', params: {id: null}})
    },
  },
};
</script>

<style scoped>
.access-group-list {
  margin: 20px;
}
</style>