<template>
  <div class="form-material mt-3">
    <div class="row">
      <div class="form-group col-2">
        <div class="form-group form-default">
          <input v-model="name" class="form-control" type="text"/>
          <span class="form-bar">
          </span>
          <label class="float-label ml-1" :class="{'fill': name}">Code</label>
        </div>
      </div>
      <div class="form-group col-1">
        <div class="form-group form-default">
          <input v-model="id" class="form-control" type="text"/>
          <span class="form-bar">
          </span>
          <label class="float-label ml-1" :class="{'fill': id}">ID</label>
        </div>
      </div>
      <div class="form-group col-1">
        <div class="form-group form-default">
          <input v-model="reference" class="form-control" type="text"/>
          <span class="form-bar">
          </span>
          <label class="float-label ml-1" :class="{'fill': reference}">Reference</label>
        </div>
      </div>
      <div class="form-group col-1">
        <button type="button" class="btn btn-primary my-0" @click="find()" :disabled="processing">Find</button>
      </div>
      <div class="form-group col-4">
        <span v-for="(location, id) of locations" :class="{'fw-bold': url==id}" 
          :key="id" class="ml-1 cursor-pointer" @click="url=id"> 
          &nbsp; &nbsp;{{id}}
        </span>
      </div>
      <div class="form-group col-2">
        <span :class="{'fw-bold': language=='EN'}" class="ml-1 cursor-pointer" @click="language='EN'"> 
          &nbsp; &nbsp;EN
        </span>
        <span :class="{'fw-bold': language=='SP'}" class="ml-1 cursor-pointer" @click="language='SP'"> 
          &nbsp; &nbsp;ES
        </span>
        <span :class="{'fw-bold': language=='DE'}" class="ml-1 cursor-pointer" @click="language='DE'"> 
          &nbsp; &nbsp;DE
        </span>
      </div>

    </div>
    <div class="row" v-if="pcms.length>0">
      <table class="table table-striped  table-sm table-bordered">
        <thead>
          <tr>
            <td>Name</td>
            <td>ID</td>
            <td>Reference</td>
            <td>Date</td>
            <td>Mostrar Precios</td>
            <td>Detalle</td>
            <td>URL</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pcm, index) of pcms" :key="'S-' + index">
            <td class="text-left"> {{pcm.NAME}}</td>
            <td class="text-right">{{pcm.PCM_ID}}</td>
            <td class="text-right">{{pcm.REFERENCE}}</td>
            <td class="text-right">{{toDateTime(pcm.DATE_ENTERED)}}</td>
            <td class="text-right">{{pcm.ANALYSIS6}}</td>
            <td class="text-center cursor-pointer" @click="$router.push({name: 'PCMCost', params: {id: pcm.PCM_ID}})">
              $
            </td>
            <td class="text-left">
              <a :href="getURL(pcm)" target="_blank" class="mr-4"> {{getURL(pcm)}}</a>
              &nbsp; &nbsp;
              <Transition mode="out-in">
                <a class="cursor-pointer" @click="copyURL(pcm, index)" v-if="!copied[index]">Copy</a>
                <span v-else>✓<i class="ml-">Copied!</i></span>
              </Transition>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script>
var moment = require('moment');
const api = window.api;
const localUrl = window.location.origin;
var locations = {
  sayhueque: 'https://yourtrip.sayhueque.com',
  saysouthamerica: 'https://yourtrip.saysouthamerica.com'
}
if (process.env.NODE_ENV == 'development') {
  locations.local = localUrl;
}

export default {
  name: 'SayPCM',
  data () {
    return {
      name: 'PCM File 020',
      id: null,
      reference: null,
      pcms: [],
      url: 'sayhueque',
      language: 'EN',
      processing: false,
      copied: {},
      locations: locations
    }
  },
  computed: {
  },
  props: {
  },
  methods: {
    async find () {
      this.processing = true;
      this.$store.commit('setProcessing', true);
      let filters = {}
      if (this.name) filters.name = this.name;
      if (this.id) filters.id = this.id;
      if (this.reference) filters.reference = this.reference;
      let res = await api.get('/api/tpsql/find_pcm', filters, 
        (err) => { console.log(err), this.processing = false});
      this.processing = false;
      this.$store.commit('setProcessing', false);
      if (res) {
        this.pcms = res;
      }
      return res;
    },
    toDateTime (d) {
      return moment(d).format('DD MMM YYYY');
    },
    getURL (pcm) {
      return `${this.locations[this.url]}/trip?ID=${pcm.PCM_ID}&Code=${pcm.NAME}&Lang=${this.language}`
    },
    copyURL (pcm, index) {
      navigator.clipboard.writeText(this.getURL(pcm));
      this.copied[index] = true;
      this.copied = Object.assign({}, this.copied);
      setTimeout(() => {
        this.copied[index] = false;
        this.copied = Object.assign({}, this.copied);
      }, 5000)
    }
  },
}
</script>

<style scoped>
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.bold {
  font-weight: bold !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
