<template>
    <vue-json-editor
        v-model="value"
        :expanded-on-start="true"
        @json-change="onJsonChange"
    ></vue-json-editor>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
export default {
    name: 'json-editor',
    props: ['data'],
    data() {
        return {
            value: {},
        };
    },
    mounted() {
        if (this.data) {
            this.value = JSON.parse(this.data);
        } else {
            this.value = { example: "example" };
        }
    },
    components: {
        'vue-json-editor': vueJsonEditor,
    },
    methods: {
        onJsonChange(value) {
            this.$emit('update:data', JSON.stringify(value));
        },
    },
};
</script>

<style>
    .jsoneditor-tree {
        min-height: 200px !important;
    }
</style>
