<template>
  <div>
    <div v-if="ready && user && user.UserName" class="app-index">
      <div class="wrapper">
        <global-notification ref="notification"></global-notification>
        <NavBar></NavBar>
        <div id="content">
            <div class="row">
                <div class="col-12">
                    <router-view :key="$route.fullPath">
                    </router-view>
                </div>
            </div>
          </div>
      </div>
    </div>
    <div v-else>
        <router-view :key="$route.fullPath" v-if="ready" >
        </router-view>
    </div>
    <Transition>
        <BlockUI v-if="processing"></BlockUI>
    </Transition>

</div>
</template>

<script>
const api = window.api;
import NavBar from '@/components/tools/NavBar.vue';
import GlobalNotification from '@/components/tools/GlobalNotification.vue'
import BlockUI from '@/components/tools/BlockUI.vue';
export default {
  name: 'SayIndex',
  components: {
    NavBar,
    GlobalNotification,
    BlockUI
  },
  data () {
    return {
      ready: false
    }
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    processing () {
      return this.$store.state.processing;
    }
  },
  async created () {
  },
  async mounted () {
    await this.load();
    this.ready = true;
  },  
  props: {
  },
  methods: {
    showNotification(message, type) {
      this.$refs.notification.showMessage(message, type)
    },
    async load () {
        let user = api.currentUser;
        if (!api.currentUser) {
            user = await api.getCurrentUser();
        }
        if (user){
          this.$store.commit('setUser', user);
        }
    }
  }
}
</script>

