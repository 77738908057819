<template>
    <User :id="userId"></User>
</template>

<script>
const api = window.api;
import User from '@/components/pages/User.vue';
export default {
    name: 'UserProfile',
    components: {
        User
    },
    data() {
        return {
            userId: api.currentUser.id
        }
    },
}
</script>