<template>
    <transition name="fade" mode="out-in">
        <div>
            <div class="blockUI blockOverlay" style="z-index: 1100; border: none; margin: 0px; padding: 0px; width: 100%; height: 100%; top: 0px; left: 0px; background-color: rgb(0, 0, 0); opacity: 0.6; cursor: wait; position: fixed;">
            </div>
            <div class="blockUI blockMsg blockPage" style="z-index: 1111; position: fixed; padding: 0px; margin: 0px; width: 30%; top: 40%; left: 35%; text-align: center; color: rgb(0, 0, 0); border: 0px; cursor: wait;">
                <div class="openerp oe_blockui_spin_container" style="background-color: transparent;">
                    <div>
                        <div style="color:white" v-if="!message">
                            Loading...
                        </div>
                        <div style="color:white" v-else>
                            {{message}}...
                        </div>
                        <div class="linear-activity">
                            <div class="indeterminate"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'block-ui',
    props: ['message'],
    computed: {
        processing () {
            return this.$store.state.processing;
        },
    },
    data () {
        return {
            timeout: null,
            requestTimeout: 60000,
            interval: null
        }
    },
    mounted () {
        var counter = 0;
        this.interval = setInterval (() => {
            counter += 1;
            if (this.processing && counter > this.requestTimeout / 1000) {
                this.$store.commit('setProcessing', false);
                clearInterval(this.interval);
            }
        }, 1000);
    },
    beforeUnmount () {
        //clearTimeout(this.timeout);
        clearInterval(this.interval);
    },
}
</script>

<style scoped>
.processing-spin {
      animation-name: spin;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: steps(12);
}

.loader-spin img { 
    max-width: 120px !important;
    max-height: 120px !important;
    -webkit-animation: rotation 2s linear infinite;
    animation: rotation 2s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


</style>
