<template>
    <div class="user-edit form-material">
      <h2>{{ isNewRecord ? 'Create User' : 'Edit User' }}</h2>
      
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="userName">Username</label>
            <input type="text" class="form-control" id="userName" v-model="record.UserName" required>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="firstName">First Name</label>
            <input type="text" class="form-control" id="firstName" v-model="record.FirstName">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="lastName">Last Name</label>
            <input type="text" class="form-control" id="lastName" v-model="record.LastName">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" v-model="record.Email">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="phone">Phone</label>
            <input type="tel" class="form-control" id="phone" v-model="record.Phone">
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="accessGroup">Access Group</label>
            <select class="form-control" id="accessGroup" v-model="record.AccessGroupId">
              <option v-for="group in accessGroups" :key="group.id" :value="group.id">
                {{ group.Description }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="password" v-model="record.Password" :required="isNewRecord">
            <small class="form-text text-muted" v-if="!isNewRecord">Leave blank to keep current password</small>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-check mb-3">
            <input type="checkbox" class="form-check-input" id="changePassword" v-model="record.ChangePassword">
            <label class="form-check-label" for="changePassword">Require password change</label>
          </div>
        </div>
      </div>
      <!-- Add this section for displaying the profile image -->
      <div class="row mt-4">
        <div class="col-12">
          <h3>Profile Image</h3>
          <div v-if="record.ProfileImageUrl" class="mb-3">
            <img :src="record.ProfileImageUrl" alt="Profile Image" class="img-thumbnail" style="max-width: 200px;">
          </div>
          <div v-else>
            <p>No profile image uploaded</p>
          </div>
          <div class="button-group">
            <button type="button" class="btn btn-info mr-2 mb-2" @click="showImageUploadModal = true">Upload New Image</button>
            <button type="button" class="btn btn-secondary mr-2 mb-2" @click="openImageSelectionModal">Select Existing Image</button>
            <button type="button" class="btn btn-danger mb-2" @click="deleteProfileImage" v-if="record.ProfileImageUrl">Delete Image</button>
          </div>
        </div>
      </div>
      <!-- New section for user descriptions -->
      <div class="row mt-4">
        <div class="col-12">
          <h3>User Descriptions</h3>
          <div v-for="(description, index) in record.UserProfiles" :key="index" class="mb-3">
            <div class="row">
              <div class="col-md-3">
                <select class="form-control" v-model="description.LanguageId">
                  <option v-for="language in languages" :key="language.id" :value="language.id">
                    {{ language.Name }}
                  </option>
                </select>
              </div>
              <div class="col-md-8">
                <textarea class="form-control" v-model="description.Description" rows="2"></textarea>
              </div>
              <div class="col-md-1">
                <button type="button" class="btn btn-danger" @click="removeDescription(index)">X</button>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-secondary" @click="addDescription">Add Description</button>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div class="btn-group" role="group">
            <button type="submit" class="btn btn-primary" @click="saveRecord">Save</button>
            <button type="button" class="btn btn-secondary" @click="cancel">Cancel</button>
            <button 
              type="button" 
              class="btn btn-danger" 
              @click="deleteRecord" 
              v-if="!isNewRecord && record.id !== currentUserId"
            >
              Delete
            </button>
          </div>
        </div>
      </div>


      <!-- Image Upload Modal -->
      <div class="modal" tabindex="-1" role="dialog" v-if="showImageUploadModal">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Upload Profile Image</h5>
              <button type="button" class="close" @click="showImageUploadModal = false">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <input type="file" class="form-control-file" id="imageUpload" @change="handleFileChange" accept="image/*">
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="showImageUploadModal = false">Close</button>
              <button type="button" class="btn btn-primary" @click="uploadImage" :disabled="!selectedFile">Upload</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Updated modal for image selection with styled close button -->
      <div class="modal" tabindex="-1" role="dialog" v-if="showImageSelectionModal">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Select an Image</h5>
              <button type="button" class="close" @click="showImageSelectionModal = false" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="image-grid">
                <div v-for="image in availableImages" :key="image.id" class="image-item" @click="selectImage(image)">
                  <img :src="image.url" :alt="image.name" class="img-thumbnail">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="showImageSelectionModal = false">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const api = window.api;
import RecordMixin from '@/components/tools/RecordMixin.vue';
export default {
  name: 'UserEdit',
  props: ['id'],
  mixins: [RecordMixin],
  data() {
    return {
      record: {
        UserName: 'aaa',
        FirstName: '',
        LastName: '',
        Email: '',
        Phone: '',
        AccessGroupId: 1,
        Password: '',
        ChangePassword: false,
      },
      table: 'user',
      accessGroups: [],
      languages: [],
      showImageUploadModal: false,
      selectedFile: null,
      showImageSelectionModal: false,
      availableImages: [],
      currentUserId: api.currentUser.id,
    };
  },
  async created() {
    this.fetchAccessGroups();
    this.fetchLanguages();
  },
  methods: {
    async fetchAccessGroups() {
      this.accessGroups = await api.get('/api/accessgroup/')
    },
    async fetchLanguages() {
      this.languages = await api.get('/api/language/')
    },
    addDescription() {
      if (!this.record.UserProfiles) {
        this.record.UserProfiles = [];
      }
      this.record.UserProfiles.push({ LanguageId: '', Description: '' });
    },
    removeDescription(index) {
      this.record.UserProfiles.splice(index, 1);
    },
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadImage() {
      if (!this.selectedFile) {
        return;
      }

      const formData = new FormData();
      formData.append('image', this.selectedFile);

      try {
        const response = await api.post('/api/upload-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        // Assuming the API returns the image URL
        this.record.ProfileImageUrl = response.url;
        
        this.showImageUploadModal = false;
        this.selectedFile = null;
        
        // Show a success message
        alert('Image uploaded successfully!');
      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Failed to upload image. Please try again.');
      }
    },
    deleteProfileImage() {
        this.record.ProfileImageUrl = null;
    
    },
    async openImageSelectionModal() {
      try {
        const response = await api.get('/api/list-images')
        this.availableImages = response;
        this.showImageSelectionModal = true;
      } catch (error) {
        console.error('Error fetching images:', error)
        // Handle error (e.g., show an error message to the user)
      }
    },
    selectImage(image) {
      this.record.ProfileImageUrl = image.url
      this.showImageSelectionModal = false
    },
  },
}
</script>

<style scoped>
.user-edit {
    margin: 0 auto;
padding: 20px;
}

.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
}

.image-item {
  cursor: pointer;
  transition: transform 0.2s;
}

.image-item:hover {
  transform: scale(1.05);
}

.image-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.btn {
  margin-right: 10px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  background-color: transparent;
  border: 0;
  appearance: none;
}

.close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

/* ... rest of the existing styles ... */
</style>