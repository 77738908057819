<template>
  <div class="form-material mt-3">
    <div class="row">
      <label class="col-md-12">PCM Cost</label>
      <label class="col-md-12" v-if="pcm">{{pcm.NAME}} {{pcm.PCM_ID}}</label>
    </div>
    <div v-if="pcm" class="mt-3">
      <table class="table table-striped  table-sm table-bordered table-responsive">
        <thead>
          <tr v-if="!details">
            <td>Base</td>
            <td>Costo</td>
            <td>Precio</td>
            <td>Ganancia</td>
            <td>%</td>
          </tr>
          <tr v-if="details">
            <td>Base</td>
            <td>Costo</td>
            <td>SGL Supp</td>
            <td>TPL Reduction</td>
            <td>Precio</td>
            <td>SGL Supp</td>
            <td>TPL Reduction</td>
          </tr>
        </thead>
        <tbody v-if="!details">
          <tr v-for="(row, index) of priceList" :key="'S-' + index">
            <td class="text-left"> {{row.base}}</td>
            <td class="text-right">{{row.cost.toFixed(2)}}</td>
            <td class="text-right">{{row.price.toFixed(2)}}</td>
            <td class="text-right">{{row.profit.toFixed(2)}}</td>
            <td class="text-right">{{row.profit_pct.toFixed(2)}}</td>
          </tr>
        </tbody>
        <tbody v-if="details">
          <tr v-for="(row, index) of cost" :key="'D-' + index">
            <td class="text-left"> {{row.PaxRange}}</td>
            <td class="text-right">{{row.Cost.toFixed(2)}}</td>
            <td class="text-right">{{row.CostSingleSupp.toFixed(2)}}</td>
            <td class="text-right">{{row.CostTripleReduction.toFixed(2)}}</td>
            <td class="text-right">{{row.Sell.toFixed(2)}}</td>
            <td class="text-right">{{row.SellSingleSupp.toFixed(2)}}</td>
            <td class="text-right">{{row.SellTripleReduction.toFixed(2)}}</td>
          </tr>
        </tbody>
      </table>

    </div>
    <button class="btn btn-primary" @click="details = !details">
      {{details ? 'Ocultar' : 'Mostrar'}} Detalle
    </button>
  </div>
</template>

<script>
const api = window.api;
const localUrl = window.location.origin;
console.log(localUrl)
export default {
  name: 'SayPCMCost',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      processing: false,
      pcm: null,
      cost: null,
      details: false,
    }
  },
  computed: {
    priceList () {
      let res = [];
      if (!this.cost) {
        return res;
      }
      for (let row of this.cost) {
        let r = {
          cost: row.Cost,
          price: row.Sell,
          base: 'DBL'
        } 
        if (row.PaxRange.trim() == '1+0') {
          r.price += row.SellSingleSupp;
          r.cost += row.CostSingleSupp;
          r.base = 'SGL';
        }
        if (row.PaxRange.trim() == '3+0') {
          r.price += -row.SellTripleReduction;
          r.cost += -row.CostTripleReduction;
          r.base = 'TPL';
        }
        r.profit = row.Sell - row.Cost;
        r.profit_pct = (row.Sell - row.Cost) / row.Sell * 100;
        res.push(r);
      }
      return res;

    }
  },
  async mounted () {
    this.processing = true;
    this.$store.commit('setProcessing', true);
    api.get('/api/tpsql/trip_prices_cost', {ID: this.id}, null, (result) => {
      this.cost = result;
    });
    let res = await api.get('/api/tpsql/find_pcm', {id: this.id}, 
      (err) => { console.log(err), this.processing = false});
    this.processing = false;
    this.$store.commit('setProcessing', false);
    if (res && res[0]) {
      this.pcm = res;
    }
  },
}
</script>

<style scoped>
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.bold {
  font-weight: bold !important;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
