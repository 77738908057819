<template>
    <div class="">
    </div>
</template>
  
  <script>
  const api = window.api;
  export default {
    name: 'RecordMixin',
    props: ['id'],
    data() {
      return {
        table: '',
        isNewRecord: true,
        record: {},
      };
    },
    created() {
      if (this.id) {
        this.fetchRecord();
      }
    },
    methods: {
      async fetchRecord() {
        if (this.id) {
          this.record = await api.get(`/api/${this.table}/${this.id}`);
          this.isNewRecord = false;
        } 
      },
      async saveRecord() {
        let id = 0;
        if (!this.isNewRecord && this.record.id) {
          id = this.record.id;
        }
        let res = await api.save(`/api/${this.table}/${id}`, this.record, (response) => {
          this.$root.$refs.index.showNotification(response, 'danger')
        });
        if (res) {
          this.$root.$refs.index.showNotification('Record saved successfully!', 'success')
          if (this.isNewRecord) {
            this.$router.replace({ name: this.table, params: { id: res.id } });
            return;
          }
          this.record = res;
          this.isNewRecord = false;
        }
      },
      cancel() {
        this.$router.push({name: `${this.table}s`});
      },
      async deleteRecord() {
        if (confirm('Are you sure you want to delete this record?')) {
            await api.remove(`/api/${this.table}/${this.id}`, (response) => {
                this.$root.$refs.index.showNotification(response, 'danger')
            });
            this.$root.$refs.index.showNotification('Record deleted successfully!', 'success')
            this.cancel();
        }
      }
    },
}
  </script>
  