import { createStore } from 'vuex';

const store = createStore({
  state () {
    return {
      user: null,
      query: {},
      loaded: false,
      processing: false,
      toPath: null,
      callConfirmClose: false,
      search: {},
      abmFilters: null,
      filters: false,
      tablePage: null,
      tableOrderBy: {ascending: true, column: null},
      record: null,

    }
  },
  mutations: {
    setUser (state, user) {
      if (user) {
        state.user = Object.assign({}, user);
      } else {
        state.user = null;
      }
    },
    setToPath (state, value) {
      state.toPath = value;
    },
    setSearch (state, {value, key}) {
        state.search[key] = value;
    },
    setFilters (state, value) {
        state.filters = value;
    },
    setAbmFilters (state, value) {
        state.abmFilters = value;
    },
    setTablePage (state, value) {
        state.tablePage = value;
    },
    setTableOrderBy (state, value) {
        state.tableOrderBy = value;
    },
    initRecord (state, {record}) {
      state.record = record;
    },
    setModified (state, value) {
      state.modified = value;
    },
    setNew (state, value) {
        state.isNew = value;
    },
    setProcessing (state, value) {
        state.processing = value;
    },


  }
})

export default store;
