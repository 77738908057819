<template>
    <div class="user-list">
      <h2>User List</h2>
      <button class="btn btn-primary mb-4" @click="addUser">Add New User</button>
      <v-client-table
        :data="users"
        :columns="columns"
        :options="options"
         @row-click="editUser"
      ></v-client-table>
    </div>
  </template>
  
  <script>
  const api = window.api;
  export default {
    name: 'UserList',
    data() {
      return {
        users: [],
        columns: ['UserName', 'FirstName', 'LastName', 'Email', 'Phone', 'actions'],
        options: {
          headings: {
            UserName: 'Username',
            FirstName: 'First Name',
            LastName: 'Last Name',
            image: 'Image',
            actions: 'Actions'
          },
          sortable: ['UserName', 'FirstName', 'LastName', 'Email', 'Phone'],
          filterable: ['UserName', 'FirstName', 'LastName', 'Email', 'Phone'],
          perPage: 10,
          perPageValues: [10, 20, 50],
          skin: 'table table-striped table-hover',
        }
      };
    },
    mounted() {
      this.fetchUsers();
    },
    methods: {
      async fetchUsers() {
        this.users = await api.get('/api/user/');
      },
      editUser(data) {
        this.$router.push({name: 'user', params: {id: data.row.id}})
      },
      addUser() {
        this.$router.push({name: 'user', params: {id: null}})
      },
    }
  }
  </script>
  
  <style scoped>
  .user-list {
    margin: 20px;
  }
  </style>