<template>
    <div class="language-list">
      <h2>Language List</h2>
      <button class="btn btn-primary mb-4" @click="addLanguage">Add New Language</button>
      <v-client-table
        :data="languages"
        :columns="columns"
        :options="options"
        @row-click="editLanguage"
      ></v-client-table>
    </div>
  </template>
  
  <script>
  const api = window.api;
  export default {
    name: 'LanguageList',
    data() {
      return {
        languages: [],
        columns: ['Code', 'Name', 'actions'],
        options: {
          headings: {
            Code: 'Language Code',
            Name: 'Language Name',
            actions: 'Actions'
          },
          sortable: ['Code', 'Name'],
          filterable: ['Code', 'Name'],
          perPage: 10,
          perPageValues: [10, 20, 50],
          skin: 'table table-striped table-hover',
        }
      };
    },
    mounted() {
      this.fetchLanguages();
    },
    methods: {
      async fetchLanguages() {
        this.languages = await api.get('/api/language/');
      },
      editLanguage(data) {
        this.$router.push({name: 'language', params: {id: data.row.id}})
      },
      addLanguage() {
        this.$router.push({name: 'language', params: {id: null}})
      },
    }
  }
  </script>
  
  <style scoped>
  .language-list {
    margin: 20px;
  }
  .mb-4 {
    margin-bottom: 16px;
  }
  </style>