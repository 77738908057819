<template>
    <div>
    </div>
</template>

<script>
const api = window.api;
export default {
    name: 'SayLogout',
    computed: {
        user () {
            return this.$store.state.user;
        }
    },
    async mounted () {
        if (!this.user) {
            this.$router.push("/login");
            return;
        }
        api.setProcessing(true);
        let res = await api.get("/api/logout");
        if (res) {
            await api.logout();
            api.setProcessing(false);
            this.$store.commit("setUser", null);
            this.$router.push("/login");
        }
    },
}
</script>

