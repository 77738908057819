<template>
    <div v-if="message" :class="['global-notification alert', `alert-${type}`, 'alert-dismissible', 'fade', 'show']" role="alert">
      {{ message }}
      <button type="button" class="close" @click="clearMessage" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'GlobalNotification',
    data() {
      return {
        message: '',
        type: 'info'
      }
    },
    methods: {
      showMessage(message, type = 'info') {
        this.message = message;
        this.type = type;
        if (type === 'success') {
          setTimeout(() => {
            this.clearMessage();
          }, 5000);
        }
      },
      clearMessage() {
        this.message = '';
      }
    }
  }
  </script>

<style scoped>
.global-notification {
  position: fixed;
  top: 8%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1050;
  min-width: 300px;
  max-width: 80%;
  text-align: center;
}

.global-notification .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
  opacity: 0.5;
  background: none;
  border: 0;
  font-size: 1.5rem;
  cursor: pointer;
  transition: opacity 0.15s;
}

.global-notification .close:hover {
  opacity: 1;
}
</style>