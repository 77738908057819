
const serverEndpoint = process.env.VUE_APP_SERVER_ENDPOINT;

class Api {

    constructor () {
        this.currentUser = null;
        this.processing = false;
        this.serverEndpoint = serverEndpoint;
        /*if (process.env.DOMAINS) {
            this.DOMAINS = Object.assign([], process.env.DOMAINS);
        }*/
        this._user = false;
        this.tableOptions = {};
        this.tablesFields = {};
        this.tables = {};
        this.getCurrentUser();
        this.language = 'en';
    }

    setProcessing (value) {
        this.processing = value;
    }

    async updateUser (res) {
        if (!res) {
            this.currentUser = await this.get('/api/current_user/');
        } else {
            this.currentUser = res;
        }
    }

    async getCurrentUser () {
        let res = this.currentUser;
        if (!res) {
                res = await this.get('/api/current_user/');
            this.currentUser = res;
            this._user = true;
        }
        return res;
    }

    logout () {
        this.currentUser = null;
    }

    getFromStore (table) {
        if (table == 'settings') return;
        if (this.tables[table]!=null) {
            return this.tables[table];
        }
    }

    setTableToStore (table, values) {
        if (!values) return;
        if (this.tables[table] && values.length < this.tables[table]) {
            return
        }
        this.tables[table] = values;
    }

    async get (e, params, handleError, callback) {
        let endpoint = new URL(this.serverEndpoint + e);
        if (params) {
            endpoint.search = new URLSearchParams(params).toString();
        }
        return new Promise((resolve, reject) => {
            fetch(endpoint, {
                    method: 'get',
                    mode: 'cors',
                    headers: {
                        'Accept': 'application/json',
                        'Content-type': 'application/json'
                    },
                    credentials: 'include',
                })
                .then(async function(response) {
                    window.api.setProcessing(false);
                    if (response.status === 200) {
                        if (callback) {
                            callback(await response.json())
                        } else {
                            resolve(response.json())
                        }
                    } else if (response.status === 404) {
                        if (handleError && response) {
                            handleError(response.statusText)
                        } else {
                            reject(response.statusText);
                        }
                    } else {
                        let res = response.json();
                        if (handleError && res) {
                            handleError(res)
                        } else {
                            reject(res);
                        }
                    }
                })
                .catch((err) => {
                    window.api.setProcessing(false);
                    if (handleError) handleError(err);
                    resolve(false)
                });
        })
    }

    async post (endpoint, data, headers, handleError, server) {
        /*let h = headers;
        if (!h) {
            h = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }*/
        if (!server) server = this.serverEndpoint;
        return new Promise((resolve, reject) => {
            fetch( server + endpoint, {
                    method: 'POST',
                    mode: 'cors',
                    credentials: 'include',
                    headers: headers,
                    body: data
                })
                .then(async function(response) {
                    window.api.setProcessing(false);
                    if (response.status === 200) {
                        resolve(response.json());
                    } else if (response.status === 404) {
                        if (handleError && response) {
                            handleError(response.statusText)
                        } else {
                            reject(response.statusText);
                        }
                    } else if (response.status === 400) {
                        if (handleError && response) {
                            handleError(await response.json())
                        } else {
                            reject(response.statusText);
                        }
                    } else {
                        let res = await response.json();
                        if (handleError && res) {
                            handleError(res)
                        } else {
                            reject(res);
                        }
                    }
                })
                .catch(function(err) {
                    window.api.setProcessing(false);
                    if (handleError) handleError(err);
                    resolve(false)
                });
        })
    }

    async remove (endpoint, handleError) {
        let self = this;
        return new Promise((resolve, reject) => {
            fetch(self.serverEndpoint + endpoint, {
                    method: 'DELETE',
                    mode: 'cors',
                    credentials: 'include',
                })
                .then(async function(response) {
                    if (response.status === 200) {
                        resolve(response.json())
                    } else if (response.status === 404) {
                        if (handleError && response) {
                            handleError(response.statusText)
                        }
                        reject(response.statusText);
                        window.api.setProcessing(false);
                    } else {
                        let res = response.json();
                        if (handleError && res) {
                            handleError(res)
                        }
                        reject(res);
                        window.api.setProcessing(false);
                    }
                })
                .catch(function(err) {
                    window.api.setProcessing(false);
                    if (handleError) handleError(err);
                    resolve(false)
                });
        })
    }

    save (endpoint, record, handleError) {
        let self = this;
        return new Promise((resolve, reject) => {
            let h = {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
            self.post(endpoint, JSON.stringify(record), h, handleError)
                .then(async function(response) {
                    resolve(response)
                })
                .catch(function(err) {
                    console.log('SAVE', err.message, err.stack, endpoint);
                    reject(err)
                });
        })
    }


}

window.api = new Api();
